import styled from 'styled-components'

export const MenuWrapper = styled.span`
  position: relative;

  &.hamburger {
    height: 6rem;
    padding: 1.6rem 0;
  }

  .content {
    position: absolute;
    left: -0.5rem;

    .hamburger & {
      left: -7.5rem;
    }

    width: 22rem;
    padding: 1rem 0;
    z-index: 1001;
    background: #ffffff;
    border-radius: 1rem;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      & > li {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        cursor: pointer;
        max-height: 5rem;
        padding: 15px 15px 15px 30px;
        color: #000000;

        :hover {
          color: #ffffff;
          background-color: #000000;
        }

        img,
        div {
          :first-child {
            flex: 1;
            max-width: 3.2rem;
          }

          &.flag-wrapper {
            width: 3.2rem;
            height: 3.2rem;
            overflow: hidden;
            border-radius: 50%;
            margin-right: 1rem;
            border: 1px solid #4f5f76;

            svg {
              position: relative;
              left: -1.5rem;

              width: 6rem;
              height: 3rem;
            }
          }

          &.menu-item {
            flex: 1;
            margin-left: 1rem;
          }
        }
      }
    }
  }

  .title-speedy {
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    width: 15rem;
    height: 2.8rem;
    background: #ffffff;
    border-radius: 1.4rem;

    color: #000000;
    font-family: inherit;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: normal;
    line-height: 110%;
    letter-spacing: -0.01em;

    &.opened {
      border-radius: 1.4rem 1.4rem 0 0;
    }

    .icon {
      display: flex;
      width: 14px;
      height: 14px;
      margin: 0 1.2rem 0 0.9rem;
    }
    .selected {
      width: 100%;
    }
    .arrow {
      width: 11px;
      margin: 0 1rem;
    }
  }

  .content-speedy {
    z-index: 1001;
    position: absolute;

    width: 15rem;
    padding: 1rem 0 1.6rem;

    background: #ffffff;
    border-radius: 0 0 1.4rem 1.4rem;

    color: #000000;
    font-family: inherit;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: normal;
    line-height: 110%;
    letter-spacing: -0.01em;

    display: none;
    &.opened {
      display: block;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      & > li {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        cursor: pointer;
        padding: 1rem 2.5rem 1rem 3.5rem;
        color: #000000;

        :hover {
          color: #ffffff;
          background: #000000;
        }
      }
    }
  }

  &.theme-light {
    .title-speedy {
      color: #ffffff;
      background-color: #000000;

      .icon {
        * {
          fill: #ffffff;
        }
      }
      .arrow {
        * {
          stroke: #ffffff;
        }
      }
    }
    .content-speedy {
      background-color: #000000;
      ul {
        & > li {
          color: #ffffff;
        }
      }
    }
  }
`
