// noinspection CssUnusedSymbol

import styled from 'styled-components'

import SecurityShieldIcon from '../../images/icons/security-shield.svg'
import SecurityShieldActiveIcon from '../../images/icons/security-shield-active.svg'
import UserMenuIcon from '../../images/icons/user-menu.svg'
import UserMenuActiveIcon from '../../images/icons/user-menu-active.svg'

export const SettingsWrapper = styled.div`
  grid-area: main-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 9rem 2rem 0;
`

export const SettingsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 82.5rem;

  @media (min-width: 1225px) {
    display: grid;
    grid-template-columns: 1fr 20rem 82.5rem 20rem 1fr;
    max-width: unset;
  }
`

export const TabsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  @media (min-width: 1225px) {
    grid-column: 2;
    flex-flow: column nowrap;
    justify-content: flex-start;
  }
`

export const TabWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  width: 20rem;
  height: 5.8rem;
  padding: 0 4rem 0 2rem;

  cursor: pointer;

  background: #202020;
  border-radius: 1rem 1rem 0 0;

  color: #808080;
  font-size: 1.6rem;
  line-height: 120%;
  text-decoration: none;

  .icon {
    margin-right: 1.5rem;

    width: 2rem;
    height: 2rem;
    &.person {
      //prettier-ignore
      background: url("${UserMenuIcon}") center center no-repeat;
    }
    &.shield {
      //prettier-ignore
      background: url("${SecurityShieldIcon}") center center no-repeat;
    }
  }

  &.active {
    background: #1a1a1a;
    color: #ffffff;

    .icon {
      &.person {
        //prettier-ignore
        background: url("${UserMenuActiveIcon}") center center no-repeat;
      }
      &.shield {
        //prettier-ignore
        background: url("${SecurityShieldActiveIcon}") center center no-repeat;
      }
    }
  }

  @media (min-width: 1225px) {
    width: 100%;
    padding: 0 2rem;
    border-radius: 1rem 0 0 1rem;
    justify-content: flex-start;
  }
`

export const PageWrapper = styled.div`
  background: #1a1a1a;
  padding: 2rem 3rem 5rem;

  @media (min-width: 1225px) {
    grid-column: 3;
    border-radius: 0 1rem 1rem 1rem;
    padding: 5rem 7rem 9rem;
  }

  .container {
    padding: 0 !important;
  }
`

export const PageTitle = styled.div`
  display: grid;
  justify-items: center;
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 4.8rem;
  line-height: 100%;
  color: #ffffff;
  padding: 0 0 6.5rem 0;
  grid-area: title;

  @media (min-width: 360px) {
    font-size: 6.4rem;
  }
`
