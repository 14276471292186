import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { ModalContainer, ModalWrapper } from './ModalDialog.styled'
import { useModal } from 'mmfintech-commons'

import CloseIcon from '../../images/icons/modal-close.svg?react'

export const ModalDialog = ({ content, options, visible, onClose }) => {
  const { ref, width, checkWidth, handleClose, overflowing, modalOptions, handleMouseDown } = useModal({
    options,
    visible,
    onClose
  })

  const location = useLocation()

  useEffect(() => {
    visible && handleClose()
    // eslint-disable-next-line
  }, [location.pathname])

  if (visible) {
    return (
      <ModalWrapper
        onMouseDown={handleMouseDown}
        className={cn({ overflowing: overflowing || width < checkWidth })}
        ref={ref}>
        <ModalContainer
          className={cn('icon-' + modalOptions.closeIconPosition, { scrollable: modalOptions.scrollable })}
          checkWidth={checkWidth}>
          <div className='popup-main-content' onMouseDown={e => e.stopPropagation()}>
            {content}
            {!modalOptions.hideCloseIcon && (
              <div className='popup-close-button-inline' onClick={handleClose}>
                <CloseIcon />
              </div>
            )}
          </div>
          {!modalOptions.hideCloseIcon && (
            <div className='popup-close-button' data-test='popup-close-button' onClick={handleClose}>
              <CloseIcon />
            </div>
          )}
        </ModalContainer>
      </ModalWrapper>
    )
  }

  return null
}

ModalDialog.propTypes = {
  content: PropTypes.any,
  options: PropTypes.shape({
    closeIconPosition: PropTypes.oneOf(['default', 'speedy-special']),
    closeOnClickOutside: PropTypes.bool,
    closeOnEscape: PropTypes.bool,
    hideCloseIcon: PropTypes.bool,
    scrollable: PropTypes.bool,
    maxWidth: PropTypes.number,
    onClose: PropTypes.func
  }),
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}
