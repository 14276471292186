// noinspection CssUnusedSymbol

import styled from 'styled-components'

export const ProfileWrapper = styled.div`
  grid-area: main-content;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  @media (min-width: 640px) {
    width: 100%;
  }
`

export const ProfileContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  width: 100%;
  max-width: 40rem;

  padding: 6rem 0;
  margin-bottom: 0;

  background: #1a1a1a;
  border-radius: 1rem;

  @media (min-width: 640px) {
    max-width: 82.5rem;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  flex: 1;
  min-width: 22rem;
  padding: 0 1rem;

  p {
    color: #808080;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;
    text-align: center;
    margin: 2rem 0 7rem;
  }
`

export const InfoContainer = styled.div`
  width: 100%;
  margin-bottom: 7rem;

  color: #ffffff;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;

  .row {
    display: flex;
    flex-flow: column nowrap;

    margin-bottom: 1.5rem;
    :last-of-type {
      margin-bottom: 0;
    }

    .label {
      color: #808080;
      font-size: 1.3rem;
      font-style: normal;
      font-weight: normal;
      line-height: 120%;
    }
  }

  @media (min-width: 360px) {
    font-size: 1.8rem;
  }
`

export const LoaderWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;

  flex: 3;
  min-width: 60rem;
  height: 100%;
  padding: 0 1rem;
  position: relative;
`

export const CustomerServiceWrapper = styled.div`
  color: #808080;
  font-size: 1.6rem;
  line-height: 120%;

  a {
    color: #ffffff;
    text-decoration: none;
  }
`
