// noinspection DuplicatedCode

import { Preloader } from 'mmfintech-portal-commons'
import { AccountOverviewRow } from './AccountOverviewRow'
import { AccountOverviewListWrapper } from './AccountOverview.styled'

import { useSelector } from 'react-redux'
import { usePermissionsRedux } from 'mmfintech-backend-api'

import { AccountAccessResponse, AccountBalanceOut } from 'mmfintech-commons-types'

export const AccountOverviewList = () => {
  const { activePaymentAccounts, activePaymentAccountsFetching } = useSelector(
    ({ banking: { activePaymentAccounts, activePaymentAccountsFetching } }: any) => ({
      activePaymentAccounts,
      activePaymentAccountsFetching
    })
  )

  const { accountAccess } = usePermissionsRedux()

  if (activePaymentAccountsFetching) {
    return <Preloader />
  }

  const accounts = activePaymentAccounts?.map((a: AccountBalanceOut) => {
    const match = accountAccess?.find((perm: AccountAccessResponse) => a?.id === perm?.accountId)
    return { ...a, permission: match?.accessLevel }
  })

  return (
    <AccountOverviewListWrapper>
      {Array.isArray(accounts) &&
        accounts.map((account: any, index: number) => <AccountOverviewRow account={account} key={index} />)}
    </AccountOverviewListWrapper>
  )
}
