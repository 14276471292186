import styled from 'styled-components'

export const CurrencyIconWrapper = styled.span<{ size: string; className?: string }>`
  display: inline-block;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;

  & > img {
    border-radius: 50%;
    width: 100%;
  }

  &.card {
    border: 1px solid white;
  }
`
