import { useEffect, useState } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'

import moment from 'moment'

import { OnboardingBanner } from '../../../onboarding'
import { SelectInput, whiteOutline } from '../../../../components'
import {
  MainPaneButtons,
  MainPaneContainer,
  MainPaneHeader,
  MainPaneWrapper,
  StyledButton
} from '../InvoicesPane/InvoicesPane.styled'

import { tr, isValidArray, formatMoney } from 'mmfintech-commons'
import { actions, periodOptions, periodType } from 'mmfintech-backend-api'

import MoneyInIcon from '../../../../images/icons/money-in-icon.svg?react'
import MoneyOutIcon from '../../../../images/icons/money-out-icon.svg?react'
import NetWorthIcon from '../../../../images/icons/net-worth-icon.svg?react'

export const CashFlowPane = () => {
  const { merchantCurrency } = useSelector(
    ({ user: { merchantCurrency, selectedLanguage } }: any) => ({
      merchantCurrency,
      selectedLanguage
    }),
    shallowEqual
  )
  const [currencyCode, setCurrencyCode] = useState(null)
  const [inflowAmount, setInflowAmount] = useState(0)
  const [outflowAmount, setOutflowAmount] = useState(0)
  const [balanceChange, setBalanceChange] = useState(0)
  const [selectedPeriod, setSelectedPeriod] = useState<string>(periodType.MONTH)

  const dispatch = useDispatch()

  const prepareFilter = () => {
    switch (selectedPeriod) {
      case periodType.MONTH:
        return {
          dateFrom: moment().startOf('month').format('YYYY-MM-DD')
        }

      default:
        return {
          dateFrom: '2010-01-01',
          dateTo: moment().format('YYYY-MM-DD')
        }
    }
  }

  useEffect(() => {
    dispatch(
      actions.merchant.fetchAggregatedBalanceChange(prepareFilter(), (response: any) => {
        if (isValidArray(response.data)) {
          const primaryCurrency = response.data.find((account: any) => account.currency === merchantCurrency)
          const {
            inflowAmount: moneyIn,
            outflowAmount: moneyOut,
            balanceChange: balance,
            currency
          } = primaryCurrency || response.data[0]
          setCurrencyCode(currency)
          setInflowAmount(moneyIn)
          setOutflowAmount(moneyOut)
          setBalanceChange(balance)
        }
      })
    )
    // eslint-disable-next-line
  }, [selectedPeriod])

  return (
    <MainPaneWrapper>
      <MainPaneHeader>
        <MainPaneContainer>
          <div className='title'>{tr('FRONTEND.INVOICES.LIST.CASH_FLOW', 'Cash flow')}</div>

          <SelectInput
            id='period'
            name='period'
            className='mr-2 selected-period'
            value={selectedPeriod}
            onChange={(_name: string, value: string) => setSelectedPeriod(value)}
            options={periodOptions()}
            applyStyles={whiteOutline}
            disableSearch={true}
            valueIsObject={true}
            hideErrorLine
            hideLabel
          />
        </MainPaneContainer>

        <OnboardingBanner button className='dashboard' />
      </MainPaneHeader>

      <MainPaneButtons className='triple'>
        <StyledButton className='green-shadow static' data-test='money-in-button'>
          <MoneyInIcon className='icon' />
          <div className='label'>
            <div className='amount' data-test='money-in-amount'>
              {formatMoney(inflowAmount, currencyCode ?? merchantCurrency)}
            </div>
            {tr('FRONTEND.DASHBOARD.MONEY_IN', 'Money In')}
          </div>
        </StyledButton>

        <StyledButton className='red-shadow static' data-test='money-out-button'>
          <MoneyOutIcon className='icon' />
          <div className='label'>
            <div className='amount' data-test='money-out-amount'>
              {formatMoney(outflowAmount, currencyCode ?? merchantCurrency)}
            </div>
            {tr('FRONTEND.DASHBOARD.MONEY_OUT', 'Money Out')}
          </div>
        </StyledButton>

        <StyledButton className='magenta-shadow static' data-test='net-worth-button'>
          <NetWorthIcon className='icon' />
          <div className='label'>
            <div className='amount' data-test='net-worth-amount'>
              {formatMoney(balanceChange, currencyCode ?? merchantCurrency)}
            </div>
            {tr('FRONTEND.DASHBOARD.NET_WORTH', 'Net worth')}
          </div>
        </StyledButton>
      </MainPaneButtons>
    </MainPaneWrapper>
  )
}
