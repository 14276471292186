import styled from 'styled-components'

export const GraphPaneWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 3.5rem;
  grid-template-columns: 1fr;
  margin-bottom: 5rem;

  @media (min-width: 900px) {
    grid-template-columns: 1fr auto;
  }
`

export const ExchangePaneWrapper = styled.div``

export const ExchangePaneContent = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr;

  @media (min-width: 640px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 900px) {
    grid-template-columns: 1fr;
  }
`
