import cn from 'classnames'

import { CurrencyIcon } from '../../../../components'
import { ButtonWrapper } from './FxRateButton.styled'

import { formatFloat } from 'mmfintech-commons'

export const FxRateButton = ({ node, precision = 2 }) => {
  const { fromCurrency, toCurrency, rate, change } = node
  const { changePercentage } = change || {}

  return (
    <ButtonWrapper>
      <div className='inner icon'>
        <CurrencyIcon currency={fromCurrency} size='4rem' />
      </div>
      <div className='inner'>
        <div className='rate'>
          <span className='currency'>
            {fromCurrency}/{toCurrency}
          </span>
          <span className='price'>{formatFloat(Math.abs(rate), precision)}</span>
        </div>
      </div>
      <div className='inner'>
        <div
          className={cn('change', {
            positive: changePercentage > 0,
            negative: changePercentage < 0
          })}>
          {Math.abs(changePercentage) > 0 ? `${changePercentage}%` : null}
        </div>
      </div>
    </ButtonWrapper>
  )
}
