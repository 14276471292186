// noinspection CssUnusedSymbol

import styled from 'styled-components'

export const DropDownWrapper = styled.span`
  position: relative;
`

export const DropDownButtonStyled = styled.div`
  border: 1px solid #ffffff;
  border-radius: 4.4rem;
  cursor: pointer;

  min-width: 11rem;
  height: 3.4rem;
  padding: 0 1.5rem;

  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  color: #afafaf;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  white-space: nowrap;

  &.opened {
    color: #ffffff;
  }

  .icon {
    margin-right: 8px;
  }
  .chevron {
    margin-left: 1.8rem;
  }
`

export const DropDownContent = styled.div`
  z-index: 1001;
  position: absolute;
  top: 4.5rem;
  right: 0;

  display: none;
  &.opened {
    display: inline-block;
  }
`
