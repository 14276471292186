import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Preloader } from 'mmfintech-portal-commons'

import { actions, paths } from 'mmfintech-backend-api'

export const LogoutHandler = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.auth.logout())
    history.push(paths.login())
  }, [])

  return <Preloader />
}
