import PropTypes from 'prop-types'

import { Wrapper } from './ContentWrapper.styled'

export const ContentWrapper = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

ContentWrapper.propTypes = {
  children: PropTypes.array.isRequired
}
