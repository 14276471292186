import { useContext, useEffect, useState } from 'react'

import { ThemeContext } from 'styled-components'

import cn from 'classnames'

import Select, { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'
import { SelectInputWrapper } from './SelectInput.styled'

import { isValidFunction } from 'mmfintech-commons'

import { SelectOption } from 'mmfintech-commons-types'
import { SelectInputProps } from './SelectInput.types'

export const SelectInput = (props: SelectInputProps) => {
  // noinspection JSCheckFunctionSignatures
  const themeContext = useContext(ThemeContext)

  const {
    id,
    name,
    async,
    error,
    label,
    theme,
    value,
    options,
    onChange,
    dataTest,
    disabled,
    required = false,
    className,
    creatable,
    hideLabel,
    applyStyles,
    loadOptions,
    placeholder,
    menuPosition,
    hideRequired,
    disableSearch,
    hideErrorLine,
    menuPlacement,
    indicatorIcon,
    loadingMessage,
    onCreateOption,
    preselectValue,
    parentClassName,
    noOptionsMessage,
    formatCreateLabel,
    valueIsObject
  } = props

  const [selectedOption, setSelectedOption] = useState<SelectOption>(null)

  const getPlaceholder = () => {
    if (placeholder?.length) return placeholder
    return null
  }

  const handleCreate = (value: string): void => {
    isValidFunction(onCreateOption) && onCreateOption(value)
    setSelectedOption({ label: value, value })
  }

  const handleChange = (option: SelectOption): void => {
    isValidFunction(onChange) && onChange(name, option.value)
    setSelectedOption(selectedOption)
  }

  const isLabelVisible = () => !!label?.length

  const prepareIndicatorIcon = () => {
    if (indicatorIcon) {
      // noinspection JSUnusedGlobalSymbols
      return {
        DropdownIndicator: (props: any) => (
          <components.DropdownIndicator {...props}>{indicatorIcon}</components.DropdownIndicator>
        )
      }
    }
    return null
  }

  const defaultStyles = {
    menu: (provided: any) => ({
      ...provided,
      fontSize: '1.5rem',
      border: 'none !important',
      backgroundColor: '#ffffff !important'
    }),
    control: (provided: any) => ({
      ...provided,
      height: '5rem',
      borderRadius: '1rem',
      borderColor: error && error.length > 0 ? themeContext.errorDisplay.borderColor : '#202020',
      borderBottomColor: error && error.length > 0 ? themeContext.errorDisplay.borderBottomColor : '#202020',
      boxShadow: 'none',
      backgroundColor: '#ffffff'
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontSize: '1.4rem',
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: '#828282'
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      color: '#000000',
      fontSize: '1.5rem',
      fontWeight: '500',
      padding: '0.2rem 1.4rem'
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#000000'
    }),
    option: (provided: any, { isFocused }) => ({
      ...provided,
      color: isFocused ? '#ffffff' : '#000000',
      backgroundColor: isFocused ? '#828282' : 'transparent'
    })
  }

  useEffect(() => {
    if (!async && !creatable) {
      const option = options?.find(item => String(item.value) === String(value))
      setSelectedOption(option ?? null)
    }
    // eslint-disable-next-line
  }, [value, options, selectedOption])

  useEffect(() => {
    if (preselectValue) {
      if (Array.isArray(options)) {
        const find = options.find(v => String(v.value) === String(preselectValue))
        if (find) {
          handleChange(find)
        }
      }
    }
    // eslint-disable-next-line
  }, [preselectValue, options])

  const addDataTest = (Component: any, dataTest: string) => (props: any) => (
    <Component {...props} innerProps={Object.assign({}, props.innerProps, { 'data-test': dataTest })} />
  )

  return (
    <SelectInputWrapper className={cn('select-wrapper', parentClassName, theme)}>
      {!hideLabel ? (
        <label>
          {isLabelVisible() && required && !hideRequired && <span className='asterisk'>*</span>}
          {isLabelVisible() && <span>{label}</span>}
          {!isLabelVisible() && ' '}
        </label>
      ) : null}

      {async ? (
        <AsyncSelect
          id={id || name}
          name={name}
          isDisabled={disabled}
          placeholder={getPlaceholder()}
          className={cn('select-input', className, { error: error && error.length > 0 })}
          loadOptions={loadOptions}
          loadingMessage={() => loadingMessage}
          defaultOptions
          value={selectedOption}
          styles={applyStyles || defaultStyles}
          onChange={handleChange}
          noOptionsMessage={() => noOptionsMessage || 'No options'}
          required={required}
          components={{
            IndicatorSeparator: () => null,
            ...(dataTest?.length ? { SelectContainer: addDataTest(components.SelectContainer, dataTest) } : null)
          }}
          menuPlacement={menuPlacement || 'bottom'}
          menuPosition={menuPosition || 'absolute'}
        />
      ) : creatable ? (
        <CreatableSelect
          id={id || name}
          name={name}
          isDisabled={disabled}
          isSearchable={!disableSearch}
          placeholder={getPlaceholder()}
          className={cn('select-input', className, { error: error && error.length > 0 })}
          options={options}
          value={selectedOption}
          styles={applyStyles || defaultStyles}
          onChange={handleChange}
          onCreateOption={handleCreate}
          noOptionsMessage={() => noOptionsMessage || 'No options'}
          required={required}
          components={{
            IndicatorSeparator: () => null,
            ...(dataTest?.length ? { SelectContainer: addDataTest(components.SelectContainer, dataTest) } : null),
            ...prepareIndicatorIcon()
          }}
          formatCreateLabel={formatCreateLabel}
          menuPlacement={menuPlacement || 'bottom'}
          menuPosition={menuPosition || 'absolute'}
        />
      ) : (
        <Select
          id={id || name}
          name={name}
          isDisabled={disabled}
          isSearchable={!disableSearch}
          placeholder={getPlaceholder()}
          className={cn('select-input', className, { error: error && error.length > 0 })}
          options={options}
          value={valueIsObject ? selectedOption || { value: value, label: value } : selectedOption}
          styles={applyStyles || defaultStyles}
          onChange={handleChange}
          noOptionsMessage={() => noOptionsMessage || 'No options'}
          required={required}
          components={{
            IndicatorSeparator: () => null,
            ...(dataTest?.length ? { SelectContainer: addDataTest(components.SelectContainer, dataTest) } : null),
            ...prepareIndicatorIcon()
          }}
          menuPlacement={menuPlacement || 'bottom'}
          menuPosition={menuPosition || 'absolute'}
        />
      )}

      {!hideErrorLine || error ? <span className='error-message'>{error}</span> : null}
    </SelectInputWrapper>
  )
}
