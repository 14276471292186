import { initialize } from 'mmfintech-backend-api'

initialize(import.meta.env)

const settings = {
  cookieDomain: '.atabank.io',
  customerServiceUrl: 'https://globalhelp.atabank.io/hc/pt-br',
  landingPageUrl: 'https://atabank.io',
  languages: ['en'],

  privacyPolicyUrl: '', // todo
  termsAndConditionsUrl: '', // todo

  defaultUploadInvoiceAcceptType: ['pdf', 'jpg', 'jpeg', 'png'],
  defaultUploadInvoiceMaxFileSize: 1024 * 1024
}

export default settings
