import styled from 'styled-components'

import LogoutIcon from '../../../images/icons/logout.svg'
import LogoutIconHover from '../../../images/icons/logout-hover.svg'
import UserMenuIcon from '../../../images/icons/user-menu-active.svg'
import UserMenuIconHover from '../../../images/icons/user-menu-hover.svg'

export const DropDownMenuWrapper = styled.div`
  position: relative;

  &.hamburger {
    height: 6rem;
    display: flex;
    align-items: center;
    padding: 1.6rem 0;
  }
`

export const DropDownMenuContainer = styled.div`
  left: -15rem;
  position: absolute;
  width: 22rem;
  z-index: 1001;

  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);

  .account-info {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    padding: 1rem;
    margin: 0 1.5rem 1rem;
    border-bottom: 1px solid #eeeeee;

    span {
      color: #808080;
      font-size: 1.3rem;
      font-style: normal;
      font-weight: normal;

      :first-child {
        color: #000000;
        font-size: 1.5rem;
      }
    }
  }

  .menu-item {
    height: 1rem;
    padding: 1.6rem 2.5rem;
    text-align: center;

    color: #000000;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.9rem;

    justify-content: flex-start;
    border: 2px solid transparent;

    :hover {
      border: 2px solid transparent;
      color: #000000;
      background: rgba(220, 223, 227, 0.4);
    }
    &.selector {
      margin: 0 1rem;
      &:first-of-type {
        margin-top: 0.5rem;
      }
      :hover {
        border-radius: 4px;
      }
    }
    &.logout {
      margin: 2rem 6rem;
      justify-content: center;
      color: #ffffff;
      background: #000000;
      padding: 2rem 4rem;
      border-radius: 4px;

      .popup-menu-icon-logout {
        //prettier-ignore
        background: url("${LogoutIcon}") center center no-repeat;
        background-size: contain;
        width: 20px;
        height: 23px;
      }
      :hover {
        border: 2px solid transparent;
        color: #ffffff;
        background: #000000;

        .popup-menu-icon-logout {
          //prettier-ignore
          background: url("${LogoutIconHover}") center center no-repeat;
        }
      }
    }
  }

  .menu-footer {
    border-top: 1px solid #eeeeee;
    margin: 1rem 1.5rem 0;
    padding: 1rem 0 1.5rem;

    .logout {
      margin: 1rem 3rem;
      justify-content: center;
      color: #ffffff;
      background: #000000;
      padding: 2rem 4rem;
      border-radius: 4px;
    }
  }
`

export const DropDownMenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: 1.6rem 2.5rem;

  cursor: pointer;

  .menu-icon {
    width: 42px;
    height: 42px;

    &.user-menu-icon {
      //prettier-ignore
      background: url("${UserMenuIcon}") center center no-repeat;
      &.visible,
      :hover {
        //prettier-ignore
        background-image: url("${UserMenuIconHover}");
      }
    }
  }
`
