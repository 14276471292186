// noinspection CssUnusedSymbol

import styled from 'styled-components'

export const SecurityWrapper = styled.div`
  grid-area: main-content;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  padding: 2rem 1rem 5rem;
  width: 100%;

  @media (min-width: 640px) {
  }

  @media (min-width: 980px) {
    padding: 3rem 2rem 7rem 2rem;
  }

  .err-msg-wrapper {
    width: auto;
  }
`

export const SecurityContainer = styled.div`
  width: 100%;
  //max-width: 40rem;
  background: #1a1a1a;
  border-radius: 1rem;
  padding: 1rem 0 0 0;
  margin-bottom: 0;

  @media (min-width: 640px) {
    max-width: 82.5rem;
  }
`

export const SecurityField = styled.div`
  color: #ffffff;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  //flex: 1;

  height: 8.8rem;
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  width: 100%;

  &.delete {
    margin-top: 1rem;
    border-top: 1px solid #4f4f4f;
    display: flex;
    flex-direction: column;
  }

  .label {
    width: 100%;
    color: #808080;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;
  }

  .content {
    overflow-wrap: anywhere;
    word-break: break-all;

    .disabled {
      color: #ff0431;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: normal;
    }
  }

  .content-delete {
    margin-top: 1rem;
    .button {
      width: 15.4rem;
    }
  }

  .icon {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: right;
    width: 100%;
    svg > * {
      fill: #777777;
      -webkit-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }

    :hover {
      svg > * {
        fill: #ffffff;
        -webkit-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
      }
    }
  }

  @media (min-width: 360px) {
    font-size: 1.8rem;
  }

  @media (min-width: 800px) {
    height: 8.8rem;
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;
    width: 100%;

    .label {
      margin-top: 3.3rem;
    }

    .content {
      margin-top: 1rem;
    }

    .content-delete {
      margin-top: -2.2rem;
    }

    .icon {
      margin-top: 2.6rem;
      margin-bottom: 0;
      width: auto;
    }
  }
`

export const ModalContainer = styled.form`
  width: 59rem;
  max-width: 98vw;
  padding: 6rem 1rem 2rem;

  @media (min-width: 600px) {
    padding: 5rem 7rem 6rem;
  }

  background: #1a1a1a;

  .title {
    color: #ffffff;
    font-size: 4rem;
    font-style: normal;
    font-weight: normal;
    line-height: 100%;

    margin-bottom: 4rem;
  }

  .container {
    background: #ffffff;
    backdrop-filter: blur(44px);
    border-radius: 7px;
    padding: 7px 0 4rem;
    width: 100%;

    .buttons {
      margin-top: 3rem;
    }
  }

  .buttons-container {
    margin-top: 5rem;
    text-align: center;

    .button {
      max-width: 39rem;
    }
  }

  #info-tooltip {
    * {
      text-align: left;
    }
  }
`
