import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import cn from 'classnames'

import { DropDownMenuContainer, DropDownMenuIcon, DropDownMenuWrapper } from './UserMenu.styled'

import { tr, useDropDownMenu } from 'mmfintech-commons'
import { actions, paths, configuration, getMerchantAccountTypeName } from 'mmfintech-backend-api'

export const UserMenu = ({ customMenuItem, customLogoutButton, customUserMenuHeader, UserMenuItems }) => {
  const merchant = useSelector(({ user }: any) => user.merchant, shallowEqual)

  const { visible, hideDropDown, toggleDropDown } = useDropDownMenu()

  const history = useHistory()
  const dispatch = useDispatch()

  const { name: merchantName } = merchant || {}

  const handleLogout = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    hideDropDown()
    history.push(paths.logout())
    if (!configuration.isProduction()) {
      dispatch(actions.cards.logout())
    }
    dispatch(actions.auth.logout())
  }

  const LogoutMenuItem = () => (
    <Link to='#' className='menu-item logout' onClick={handleLogout}>
      {tr('FRONTEND.HEADER.LOGOUT', 'Log Out')}
    </Link>
  )

  return (
    <DropDownMenuWrapper>
      <DropDownMenuIcon onClick={toggleDropDown}>
        <span className={cn('menu-icon', 'user-menu-icon', { visible })} />
      </DropDownMenuIcon>

      {visible && (
        <DropDownMenuContainer>
          {customUserMenuHeader && (
            <div className='account-info'>
              <span>{merchantName}</span>
              <span>{getMerchantAccountTypeName(merchant)}</span>
            </div>
          )}

          <UserMenuItems onClick={hideDropDown} className={cn('menu-item', { selector: customMenuItem })} />
          {!customLogoutButton ? (
            <div className='menu-footer'>
              <LogoutMenuItem />
            </div>
          ) : (
            <LogoutMenuItem />
          )}
        </DropDownMenuContainer>
      )}
    </DropDownMenuWrapper>
  )
}
