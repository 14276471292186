// noinspection CssUnusedSymbol

import styled from 'styled-components'

export const TransactionsStyled = styled.div`
  grid-row: 2 / 4;
  grid-column: main-content;

  padding: 0 2.5rem;
  margin: 14rem 0 0;

  display: flex;
  flex-flow: column nowrap;

  @media (min-width: 700px) {
    margin-top: 5rem;
  }
`

export const TransactionsHeaderStyled = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: column-reverse nowrap;
  margin-bottom: 2.5rem;

  @media (min-width: 1200px) {
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: space-between;
  }

  .left-side,
  .right-side {
    width: 100%;
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: center;

    @media (min-width: 640px) {
      width: auto;
      flex-flow: row nowrap;
      align-items: flex-end;
      margin-bottom: 2rem;
    }
    @media (min-width: 1024px) {
      margin-bottom: 0;
    }

    .button {
      height: 5rem;
      width: 100%;
      max-width: 30rem;

      font-size: 2rem;
      font-weight: 500;
      white-space: nowrap;
      margin-bottom: 2rem;

      @media (min-width: 640px) {
        font-size: 1.5rem;
        height: 3.5rem;
        width: auto;
        margin-left: 1.5rem;
        margin-bottom: 0.3rem;
        :first-child {
          margin-left: 0;
        }
      }
    }
  }

  .left-side {
    flex-flow: column-reverse nowrap;
    @media (min-width: 640px) {
      flex-flow: row nowrap;
    }

    .select-account-wrapper {
      margin-bottom: 2rem;
      width: 30rem;

      @media (min-width: 480px) {
        width: 35rem;
      }
      @media (min-width: 640px) {
        margin-bottom: 0;
      }
    }
  }
`

export const TransactionsPaginationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 3.5rem;

  .transactions-pagination {
    display: inline-flex;
    padding: 0;
    .pagination-container {
      margin: 0;
    }
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr auto 1fr;

    .transactions-pagination {
      grid-column: 2;
    }
  }
`

export const TransactionExportButtons = styled.div`
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: 2rem;

  .button {
    border: 1px solid #afafaf;
    margin-bottom: 1rem;
    max-width: 30rem;
    width: 100%;
  }

  @media (min-width: 480px) {
    flex-flow: row nowrap;
    justify-content: center;

    .button {
      width: auto;
      margin-bottom: 0;
      margin-right: 1.5rem;
      :last-child {
        margin-right: 0;
      }
    }
  }
  @media (min-width: 1200px) {
    grid-column: 3;
    margin-top: 0;
    justify-content: flex-end;
  }
`
