import { useState, useContext, useEffect, useMemo } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import cn from 'classnames'

import { CustomInput } from '../CustomInput'
import { EditContainer } from '../commons'
import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import { Countdown, OtpSubtitle, OtpTitle, OtpWrapper, ResendLinkWrapper, VerificationContainer } from './Otp.styled'

import { actions, globalSettings } from 'mmfintech-backend-api'
import {
  checkSingleValue,
  formatPhoneNumber,
  GlobalContext,
  isValidFunction,
  OtpContext,
  tr,
  useTimer,
  useValidUntilTimer
} from 'mmfintech-commons'

import { LoginStatusEnum, TwoFactorTypeEnum } from 'mmfintech-commons-types'

import Icon from '../../images/icons/input-otp.svg?react'

const getChallengeId = (challenge: { challengeId: number }) => {
  if (challenge?.challengeId) {
    return challenge.challengeId
  }
  console.error('Missing challengeId.')
  return null
}

const cardGatewayPurposes = ['CHANGE_PREPAID_CARD_STATUS', 'VIEW_PREPAID_CARD']

export const OtpLegacy = () => {
  const { modalHide }: any = useContext(GlobalContext)
  const { otpOnSuccess, otpOnError, setOtpOnSuccess, setOtpOnError }: any = useContext(OtpContext)

  const { userStatus, fetching, otpVerifyError, challenge } = useSelector(
    ({ user: { userStatus }, otp: { fetching, otpVerifyError, challenge } }: any) => ({
      userStatus,
      fetching,
      otpVerifyError,
      challenge
    }),
    shallowEqual
  )

  const [otp, setOtp] = useState<string>('')
  const [attempts, setAttempts] = useState<number>(0)
  const [purpose, setPurpose] = useState<string>('')

  const history = useHistory()
  const dispatch = useDispatch()

  const timer = useValidUntilTimer()
  const resendTimer = useTimer()

  const { sentTo, twoFactorType, challengePurpose, otpLength } = challenge || {}

  const codeSize = useMemo(() => (otpLength > 0 ? otpLength : globalSettings.otpCodeLength), [otpLength])

  const handleChange = (_name: string, value: string): void => {
    const newValue = checkSingleValue(value, { validation: 'numeric' })
    setOtp(newValue)
  }

  const cleanContextFunctions = (): void => {
    setOtpOnSuccess(null)
    setOtpOnError(null)
  }

  const handleSuccess = (props: any) => {
    isValidFunction(otpOnSuccess) && otpOnSuccess(props)
    if (!props?.data?.challenge) {
      cleanContextFunctions()
    }
  }

  const handleError = (props: any) => {
    isValidFunction(otpOnError) && otpOnError(props)
    if (!props?.data?.challenge) {
      setOtpOnError(null)
    }
  }

  const handleSubmit = () => {
    const challengeId = getChallengeId(challenge)
    setAttempts(attempts + 1)
    challengeId &&
      dispatch(
        cardGatewayPurposes.includes(purpose)
          ? actions.otp.verifyCardGateway({ code: otp }, challengeId, history, handleSuccess, handleError)
          : actions.otp.verify({ code: otp }, challengeId, history, handleSuccess, handleError)
      )
  }

  const handleReset = () => {
    if (resendTimer.expired) {
      const challengeId = getChallengeId(challenge)
      challengeId &&
        dispatch(
          cardGatewayPurposes.includes(purpose)
            ? actions.otp.resetCardGateway(challengeId)
            : actions.otp.reset(challengeId)
        )
      resendTimer.start(30)
    }
  }

  const prepareTitle = () => {
    if (twoFactorType === TwoFactorTypeEnum.SMS) {
      if (userStatus === LoginStatusEnum.LOGGED_IN) {
        return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_SMS', 'SMS Verification')
      }

      return tr('FRONTEND.VERIFY_OTP.TITLE_SMS', 'SMS Confirmation')
    }

    if (userStatus === LoginStatusEnum.SECOND_FACTOR || userStatus === LoginStatusEnum.LOGGED_IN) {
      return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_EMAIL', 'Email verification')
    }
    return tr('FRONTEND.VERIFY_OTP.TITLE_EMAIL', 'Email confirmation')
  }

  useEffect(() => {
    if (challengePurpose) {
      setPurpose(challengePurpose)
    }
  }, [challengePurpose])

  return (
    <OtpWrapper data-test='otp-verification-dialog'>
      <OtpTitle className='title'>{prepareTitle()}</OtpTitle>
      <OtpSubtitle>
        {tr('FRONTEND.VERIFY_OTP.TARGET_LABEL', "Please, enter the code that we've sent to:")}
        <span>{twoFactorType === TwoFactorTypeEnum.SMS ? formatPhoneNumber(sentTo) : sentTo}</span>
      </OtpSubtitle>

      <VerificationContainer>
        <EditContainer className='no-padding'>
          <div className='input-container'>
            <CustomInput
              icon={<Icon />}
              type='tel'
              name='otp'
              data-test='otp'
              className={cn({
                complete: otp?.length === codeSize,
                wrong: otp?.length === codeSize && otpVerifyError != null
              })}
              autoComplete='off'
              onChange={handleChange}
              value={otp}
              maxLength={codeSize}
              autoFocus
              placeholder={tr('FRONTEND.VERIFY_OTP.ENTER_CODE', 'Enter Code')}
              disabled={timer.expired || attempts >= globalSettings.otpMaxAttempts}
            />
          </div>

          <ErrorDisplay error={otpVerifyError} />

          <div className='buttons mt-3'>
            <Button
              color='primary'
              text={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
              disabled={otp.length !== codeSize || attempts >= globalSettings.otpMaxAttempts || timer.expired}
              loading={fetching}
              iconPosition='right'
              icon='right-arrow'
              data-test='button-submit'
              onClick={handleSubmit}
            />
            <Button
              type='button'
              color='secondary'
              text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
              disabled={fetching}
              onClick={modalHide}
              data-test='button-cancel'
            />
          </div>
          <ResendLinkWrapper>
            {attempts >= globalSettings.otpMaxAttempts ? (
              tr('FRONTEND.VERIFY_OTP.NO_MORE_ATTEMPTS', 'No more verification attempts.')
            ) : (
              <>
                {tr('FRONTEND.VERIFY_OTP.NOT_RECEIVED', "Haven't received it?")}{' '}
                {resendTimer.expired ? (
                  <Link to='#' onClick={handleReset}>
                    {tr('FRONTEND.VERIFY_OTP.RESEND_CODE', 'Re-send code')}
                  </Link>
                ) : (
                  resendTimer.remainingTime
                )}
              </>
            )}
          </ResendLinkWrapper>
          {attempts >= globalSettings.otpMaxAttempts || fetching ? null : <Countdown>{timer.formattedTime}</Countdown>}
        </EditContainer>
      </VerificationContainer>
    </OtpWrapper>
  )
}
