import styled from 'styled-components'

export const InvoicePreviewWrapper = styled.div`
  width: 96vw;
  max-width: 82rem;
  max-height: 90vh;
  overflow-y: auto;
  padding: 6.5rem 2rem 4rem;
  clip-path: polygon(0 0, calc(100% - 81px) 0, 100% 81px, 100% 100%, 0 100%);

  @media (min-width: 800px) {
    padding: 6.5rem 4.5rem 7rem;
  }

  background: #1a1a1a;
  border-radius: 1.5rem 0 1.5rem 1.5rem;

  color: #afafaf;
  font-family: 'Helvetica Now Display', sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
`

export const InvoicePreviewSection1 = styled.div`
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  .merchant-info {
    font-size: 1rem;
    .merchant-name {
      color: #ffffff;
      font-size: 1.2rem;
      margin-bottom: 0.8rem;
    }
  }

  @media (min-width: 800px) {
    margin-bottom: 6rem;
    grid-template-columns: 1fr 20rem;
    grid-gap: 3rem;
  }
`

export const InvoicePreviewSection2 = styled.div`
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  b {
    color: #ffffff;
    display: block;
    font-weight: normal;
  }

  .title {
    color: #ffffff;
    font-size: 3rem;
    font-style: normal;
    font-weight: normal;
    line-height: 100%;
  }

  @media (min-width: 800px) {
    margin-bottom: 6rem;
    grid-template-columns: 20rem 1fr 20rem;
    grid-gap: 3rem;
  }
`

export const InvoicePreviewSection3 = styled.div`
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  b {
    color: #ffffff;
    display: block;
    font-weight: normal;
  }

  @media (min-width: 800px) {
    margin-bottom: 6rem;
    grid-template-columns: 20rem 1fr 1fr 20rem;
    grid-gap: 3rem;
  }
`

export const InvoicePreviewSection4 = styled.div`
  margin-bottom: 3rem;

  table {
    width: 100%;
    display: block;
    border-collapse: collapse;

    thead {
      tr {
        display: none;
      }
    }

    .p-column-title {
      display: block;
      color: #808080;
      font-family: inherit;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: normal;
      line-height: 120%;
    }

    tbody {
      display: block;

      tr {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border: 1px solid #333333;
        border-radius: 1rem;
        padding: 1rem 2rem;
        margin-bottom: 1rem;

        td {
          display: table-cell;
          padding-bottom: 1rem;
          margin-bottom: 0.5rem;
          width: 100%;

          color: #ffffff;
          font-family: inherit;
          font-size: 1.2rem;
          font-style: normal;
          font-weight: normal;
          line-height: 120%;

          b {
            font-weight: normal;
          }
        }
      }
    }
  }

  @media (min-width: 800px) {
    margin-bottom: 6rem;

    table {
      display: table;

      thead {
        tr {
          display: table-row;

          th,
          td {
            color: #808080;
            font-family: inherit;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: normal;
            line-height: 120%;
            text-align: left;
            padding: 0 1rem;

            &.centered {
              text-align: center;
            }
            &.right-aligned {
              text-align: right;
            }

            span {
              font-size: 1rem;
            }
          }
        }
      }

      .p-column-title {
        display: none;
      }

      tbody {
        display: table-row-group;
        tr {
          display: table-row;
          border: none;
          border-bottom: 1px solid #282828;
          border-radius: 0;
          padding: 0;
          margin-bottom: 0;

          :last-of-type {
            border-bottom: none;
          }

          th {
            padding: 2rem 0;
            text-align: left;
            white-space: nowrap;
          }

          td {
            display: table-cell;
            border-bottom: none;
            padding: 2rem 1rem;
            margin-bottom: 0;
            width: auto;

            &.centered {
              text-align: center;
            }
            &.right-aligned {
              text-align: right;
            }
          }
        }
      }
    }
  }
`

export const InvoicePreviewSection5 = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  .payment-terms {
    grid-row: 2;
    border-top: 1px solid #282828;
    padding: 2rem;
  }
  .row {
    border-top: 1px solid #282828;
    padding: 1.5rem 2rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    font-size: 1.8rem;

    &.total {
      font-size: 2.6rem;
      color: #ffffff;
    }
  }

  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 5rem;

    .payment-terms {
      grid-row: auto;
    }
  }
`
