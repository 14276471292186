// noinspection CssUnusedSymbol

import styled from 'styled-components'

export const LogoWrapper = styled.div`
  width: 14rem;
  height: 14rem;
  position: relative;

  .image-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .image-item img {
    max-width: 14rem;
    max-height: 14rem;
  }

  .logo-remove-item {
    position: absolute;
    top: -1.5rem;
    right: -1.5rem;
    display: none;
    cursor: pointer;
  }

  .image-item:hover .logo-remove-item {
    display: inline-block;
  }

  .logo-loader {
    display: inline-block;
    width: 14rem;
    height: 14rem;
  }

  .logo-inner {
    position: relative;
    display: inline-block;
  }
`

export const LogoButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  cursor: pointer;
  height: 100%;
  width: 100%;

  color: #afafaf;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
  text-transform: lowercase;

  span.sign {
    color: #000000;
    font-size: 3.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  &.bordered {
    border: 1px dashed #afafaf;
    border-radius: 7rem;
    width: 14rem;
    height: 14rem;
  }

  &.bordered:hover {
    color: #85a0ad;

    .caption {
      text-decoration: underline;
    }
  }
`
