import styled from 'styled-components'

export const ActivitiesWrapper = styled.div`
  height: 100%;
  padding: 2rem 0 4.5rem;
  border-left: 1px solid #1a1a1a;

  @media (min-width: 900px) {
    padding-top: 3.5rem;
    margin-bottom: 8rem;
  }
  @media (min-width: 1175px) {
    padding-top: 8.4rem;
  }
  @media (min-width: 1400px) {
    margin-bottom: 0;
    padding-left: 2.5rem;
  }

  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
`

export const ActivitiesHeader = styled.div`
  width: 100%;
  padding: 0;
  margin-bottom: 4rem;

  @media (min-width: 1400px) {
    padding: 0 2.5rem;
  }

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  .date-selection {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 1rem;

    .selected-date {
      width: 100%;
      color: #ffffff;
      font-size: 2.4rem;
      font-family: inherit;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      cursor: pointer;

      @media (min-width: 480px) {
        font-size: 2.4rem;
      }
    }

    .icon-button {
      cursor: pointer;
      padding: 0 2rem;
    }
  }

  .subtitle {
    color: #ffffff;
    font-family: inherit;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;
  }
`

export const ActivitiesContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  color: #ffffff;
  font-family: inherit;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 137%;
`

export const ActivityWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  border-radius: 1rem;

  width: 100%;
  min-height: 9rem;
  padding: 1.8rem 0.5rem;

  transition: all 0.4s;

  @media (min-width: 800px) {
    padding: 1.8rem 2.4rem;
  }

  &.clickable {
    cursor: pointer;
  }

  :hover {
    background: #1a1a1a;
  }

  .left {
    display: flex;
    align-items: center;
  }

  .icon {
    width: 5.3rem;
    height: 5.3rem;
    margin-right: 1.4rem;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    img,
    svg {
      width: 5.3rem;
      height: 5.3rem;
    }
  }

  .description {
    //width: 100%;
    div {
      color: #808080;
      font-family: inherit;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: normal;
      line-height: 157%;
    }
  }

  .amount {
    margin-left: 1.4rem;
    min-width: 7.5rem;
    text-align: right;

    font-size: 1.6rem;
    font-style: normal;
    font-weight: bold;
    line-height: 2.2rem;

    //@media (min-width: 480px) {
    //  white-space: nowrap;
    //}
  }
`
