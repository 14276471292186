import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'

import { OnboardingBanner } from '../../../onboarding'
import { SelectInput, whiteOutline } from '../../../../components'
import {
  MainPaneButtons,
  MainPaneContainer,
  MainPaneHeader,
  MainPaneWrapper,
  StyledButton
} from './InvoicesPane.styled'

import { tr, formatMoney } from 'mmfintech-commons'
import { paths, periodType, periodOptions } from 'mmfintech-backend-api'

import TotalAmountIcon from '../../../../images/icons/invoices-total-amount.svg?react'
import PaidInvoicesIcon from '../../../../images/icons/invoices-paid.svg?react'
import OpenInvoicesIcon from '../../../../images/icons/invoices-open.svg?react'
import OverdueInvoicesIcon from '../../../../images/icons/invoices-overdue.svg?react'

export const InvoicesPane = () => {
  const { merchantCurrency, dashboard } = useSelector(
    ({ user: { dashboard, merchantCurrency, selectedLanguage } }: any) => ({
      dashboard,
      merchantCurrency,
      selectedLanguage
    }),
    shallowEqual
  )

  const [selectedPeriod, setSelectedPeriod] = useState<string>(periodType.MONTH)

  const history = useHistory()

  const { invoices } = dashboard || ''
  const { weeklySummary, monthlySummary, allTimeSummary } = invoices || {}

  const getAmount = (amountType: string, period: string) => {
    const defaultAmount = { amount: 0, currency: merchantCurrency }

    switch (period) {
      case periodType.ALL:
        return (allTimeSummary && allTimeSummary[amountType]) ?? defaultAmount
      case periodType.WEEK:
        return (weeklySummary && weeklySummary[amountType]) ?? defaultAmount
      case periodType.MONTH:
        return (monthlySummary && monthlySummary[amountType]) ?? defaultAmount
      default:
        return
    }
  }

  const formatAmount = ({ amount, currency }) => formatMoney(amount, currency)

  return (
    <MainPaneWrapper>
      <MainPaneHeader>
        <MainPaneContainer>
          <div className='title'>{tr('FRONTEND.INVOICES.LIST.TITLE', 'Invoices')}</div>

          <SelectInput
            id='period'
            name='period'
            className='mr-2 selected-period'
            value={selectedPeriod}
            onChange={(_name: string, value: string) => setSelectedPeriod(value)}
            options={periodOptions()}
            applyStyles={whiteOutline}
            disableSearch={true}
            hideErrorLine
            hideLabel
          />
        </MainPaneContainer>

        <OnboardingBanner button className='dashboard' />
      </MainPaneHeader>

      <MainPaneButtons>
        <StyledButton
          className='magenta-shadow'
          data-test='total-amount-button'
          onClick={() => history.push(paths.invoices.list())}>
          <TotalAmountIcon className='icon' />
          <div className='label'>
            <div className='amount' data-test='total-amount'>
              {formatAmount(getAmount('totalAmount', selectedPeriod))}
            </div>
            {tr('FRONTEND.DASHBOARD.BOX_TOTAL', 'Total Amount')}
          </div>
        </StyledButton>

        <StyledButton
          className='green-shadow'
          data-test='paid-invoices-button'
          onClick={() => history.push(paths.invoices.listByStatus('PAID'))}>
          <PaidInvoicesIcon className='icon' />
          <div className='label'>
            <div className='amount' data-test='paid-amount'>
              {formatAmount(getAmount('paidAmount', selectedPeriod))}
            </div>
            {tr('FRONTEND.DASHBOARD.BOX_PAID', 'Paid')}
          </div>
        </StyledButton>

        <StyledButton
          className='yellow-shadow'
          data-test='open-invoices-button'
          onClick={() => history.push(paths.invoices.listByStatus('OPENED'))}>
          <OpenInvoicesIcon className='icon' />
          <div className='label'>
            <div className='amount' data-test='open-amount'>
              {formatAmount(getAmount('openAmount', selectedPeriod))}
            </div>
            {tr('FRONTEND.DASHBOARD.BOX_OPEN', 'Open')}
          </div>
        </StyledButton>

        <StyledButton
          className='red-shadow'
          data-test='overdue-invoices-button'
          onClick={() => history.push(paths.invoices.listByStatus('OVERDUE'))}>
          <OverdueInvoicesIcon className='icon' />
          <div className='label'>
            <div className='amount' data-test='overdue-amount'>
              {formatAmount(getAmount('overdueAmount', selectedPeriod))}
            </div>
            {tr('FRONTEND.DASHBOARD.BOX_OVERDUE', 'Overdue')}
          </div>
        </StyledButton>
      </MainPaneButtons>
    </MainPaneWrapper>
  )
}
