import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import cn from 'classnames'
import styled from 'styled-components'
import Select, { components } from 'react-select'

import { isValidFunction } from 'mmfintech-commons'
import { getInvoiceStatusName, invoiceStatuses, prepareInvoiceStatusOptions } from 'mmfintech-backend-api'

import { SelectOption } from 'mmfintech-commons-types'
import { InvoiceStatusProps } from './InvoiceStatus.types'

export const InvoiceStatus = (props: InvoiceStatusProps) => {
  const { id, name, disabled, value, overdue, onChange, className, ...rest } = props

  const [selectedOption, setSelectedOption] = useState(null)
  const [translatedOptions, setTranslatedOptions] = useState([])

  const selectedLanguage = useSelector(({ user }: any) => user.selectedLanguage, shallowEqual)

  const getStatusColor = (status: string): string => (status === invoiceStatuses.PAID ? '#59b300' : '#000000')

  const getStatusBackground = (status: string): string =>
    status === invoiceStatuses.OPENED && overdue
      ? '#e8112d'
      : status === invoiceStatuses.PAID
      ? '#00b451'
      : status === invoiceStatuses.CANCELED
      ? '#828282'
      : '#f5b500'

  useEffect(() => {
    const options =
      value === invoiceStatuses.OPENED
        ? [invoiceStatuses.PAID, invoiceStatuses.CANCELED]
        : value === invoiceStatuses.CANCELED
        ? [invoiceStatuses.OPENED, invoiceStatuses.PAID]
        : []
    setTranslatedOptions(prepareInvoiceStatusOptions(options))

    setSelectedOption({
      ...selectedOption,
      label: getInvoiceStatusName(selectedOption?.value)
    })
    // eslint-disable-next-line
  }, [value, selectedLanguage])

  useEffect(() => {
    setSelectedOption({ value, label: getInvoiceStatusName(value, overdue) })
  }, [value, overdue])

  const handleChange = (selectedOption: SelectOption): void => {
    isValidFunction(onChange) && onChange(selectedOption?.value)
  }

  const customStyles = {
    control: (base: any) => ({
      ...base,
      cursor: 'pointer',
      fontWeight: 'normal',
      textAlign: 'center',
      borderRadius: '9.6rem',
      border: 'none !important',
      boxShadow: 'none',
      backgroundColor: getStatusBackground(selectedOption?.value),
      color: getStatusColor(selectedOption?.value)
    }),
    singleValue: (base: any) => ({
      ...base,
      width: '100%',
      fontSize: '1.6rem',
      fontWeight: 'normal',
      color: 'inherit',
      padding: '2px 0 2px 3.2rem'
    }),
    valueContainer: (base: any) => ({
      ...base,
      justifyContent: 'center'
    }),
    menu: (provided: any) => ({
      ...provided,
      background: '#ffffff',
      border: 'none',
      borderRadius: '1.8rem',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.7)',
      padding: '0.5rem',
      marginTop: '2rem',
      ':hover': {
        border: 'none !important'
      }
    }),
    option: (provided: any) => ({
      ...provided,
      cursor: 'pointer',
      fontSize: '1.6rem',

      color: '#000000',
      backgroundColor: 'transparent',
      lineHeight: '170%',
      ':hover': {
        color: '#ffffff',
        background: '#000000',
        borderRadius: '1rem'
      }
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      cursor: 'pointer',
      color: getStatusColor(selectedOption?.value)
    })
  }

  const addDataTest = (Component: any, dataTest: string) => (props: any) => (
    <Component {...props} innerProps={Object.assign({}, props.innerProps, { 'data-test': dataTest })} />
  )

  return (
    <Wrapper className='select-wrapper'>
      <Select
        {...rest}
        id={id}
        name={name}
        isDisabled={disabled}
        isSearchable={false}
        className={cn('invoice-status', className)}
        placeholder=''
        options={translatedOptions}
        value={selectedOption}
        styles={customStyles}
        onChange={handleChange}
        components={{
          IndicatorSeparator: () => null,
          Control: addDataTest(components.Control, selectedOption?.value?.toLowerCase())
        }}
        menuPlacement='auto'
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 4rem;
  width: 16rem;
`
