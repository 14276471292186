// noinspection CssUnusedSymbol

import styled from 'styled-components'

export const RadioButtonWrapper = styled.div`
  width: 100%;
  height: 12rem;
  padding: 0 2rem;
  cursor: pointer;

  display: grid;
  grid-template-columns: 25px 1fr;
  grid-gap: 2rem;
  border: 1px solid transparent;

  &.selected {
    border-color: #4f4f4f;
    backdrop-filter: blur(44px);
    border-radius: 7px;
  }

  .content {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    height: 100%;

    &.centered {
      align-items: center;
    }

    &.icon {
      display: none;
    }

    color: #808080;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;

    .label {
      color: #ffffff;
      font-size: 2.4rem;
      font-style: normal;
      font-weight: normal;
      line-height: 120%;
      margin-bottom: 0.3rem;
    }

    .value {
      overflow-wrap: anywhere;
      word-break: break-all;
    }
  }

  @media (min-width: 480px) {
    grid-template-columns: 25px 70px 1fr;

    .content.icon {
      display: flex;
    }
  }
`
