import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import cn from 'classnames'
import { TailSpin } from 'react-loader-spinner'
import DatePicker from 'react-datepicker'

import { ErrorDisplay } from 'mmfintech-portal-commons'
import { ActivitiesContent, ActivitiesHeader, ActivitiesWrapper, ActivityWrapper } from './ActivitiesPane.styled'

import { actions, paths, transformMerchantActivity, useActivities } from 'mmfintech-backend-api'
import { compareDates, formatMoney, isValidArray, tr, translateMonthName } from 'mmfintech-commons'

import { ActivityTypeEnum } from 'mmfintech-commons-types'

import BackIcon from '../../../images/icons/date-back-arrow.svg?react'
import NextIcon from '../../../images/icons/date-next-arrow.svg?react'

import activityNoneIcon from '../../../images/icons/activity-none.svg'
import activityDepositIcon from '../../../images/icons/activity-deposit.svg'
import activityProfileIcon from '../../../images/icons/activity-profile.svg'
import activityTransferIcon from '../../../images/icons/activity-transfer.svg'
import activityWithdrawalIcon from '../../../images/icons/activity-withdrawal.svg'
import activityInvoicePaidIcon from '../../../images/icons/activity-invoice-paid.svg'
import activityInvoiceOpenedIcon from '../../../images/icons/activity-invoice-opened.svg'
import activityInvoiceCreatedIcon from '../../../images/icons/activity-invoice-created.svg'
import activityCustomerCreatedIcon from '../../../images/icons/activity-customer-created.svg'

export const ActivitiesPane = () => {
  const {
    lastActivities,
    lastActivitiesError,
    lastActivitiesFetching,
    invoiceFetching,
    invoicePreviewFetching,
    selectedLanguage
  } = useSelector(
    ({
      invoices: { invoiceFetching, invoicePreviewFetching },
      user: { lastActivities, lastActivitiesError, lastActivitiesFetching, selectedLanguage }
    }: any) => ({
      invoiceFetching,
      invoicePreviewFetching,
      lastActivities,
      lastActivitiesError,
      lastActivitiesFetching,
      selectedLanguage
    }),
    shallowEqual
  )

  const [datum, setDatum] = useState(new Date())

  const dispatch = useDispatch()

  const { previewIndex, handleActivityClick } = useActivities({
    securityPath: paths.settings(),
    handleInvoicePreview: (invoiceId: number) => {
      dispatch(actions.invoice.fetchInvoicePreviewForSpeedy(invoiceId))
    }
  })

  const getDateText = () => {
    const today = new Date()
    const dayOfMonth = datum.getDate()
    const month = translateMonthName(datum.getMonth() + 1)

    if (
      datum.getDate() === today.getDate() &&
      datum.getMonth() === today.getMonth() &&
      datum.getFullYear() === today.getFullYear()
    ) {
      const dayOfMonth = datum.getDate()
      const month = translateMonthName(datum.getMonth() + 1)

      return tr('FRONTEND.DASHBOARD.ACTIVITIES_TODAY', 'Today') + `, ${dayOfMonth} ${month.substring(0, 3)}.`
    }

    return `${dayOfMonth} ${month.substring(0, 3)}.`
  }

  const getActivityIcon = (type: string) => {
    switch (type) {
      case ActivityTypeEnum.ACCOUNT_APPROVED:
      case ActivityTypeEnum.REGISTERED_ACCOUNT:
        return activityProfileIcon

      case ActivityTypeEnum.CONTACT_CREATED:
      case ActivityTypeEnum.CUSTOMER_CREATED:
        return activityCustomerCreatedIcon

      case ActivityTypeEnum.ENOTAS_INVOICE_APPROVED:
      case ActivityTypeEnum.ENOTAS_INVOICE_CANCELED:
      case ActivityTypeEnum.ENOTAS_INVOICE_DECLINED:
      case ActivityTypeEnum.INVOICE_CREATED:
        return activityInvoiceCreatedIcon

      case ActivityTypeEnum.INVOICE_OPENED:
        return activityInvoiceOpenedIcon
      case ActivityTypeEnum.INVOICE_PAID:
        return activityInvoicePaidIcon

      case ActivityTypeEnum.TRANSFER_SENT:
      case ActivityTypeEnum.TRANSFER_RECEIVED:
      case ActivityTypeEnum.EXCHANGE_PROCESSED:
        return activityTransferIcon

      case ActivityTypeEnum.DEPOSIT_PROCESSED:
      case ActivityTypeEnum.DEPOSIT_RETURNED:
        return activityDepositIcon

      case ActivityTypeEnum.WITHDRAWAL_PENDING:
      case ActivityTypeEnum.WITHDRAWAL_REJECTED:
      case ActivityTypeEnum.WITHDRAWAL_PROCESSED:
        return activityWithdrawalIcon

      default:
        return activityNoneIcon
    }
  }

  const handleDateBack = () => {
    const d = new Date(datum)
    d.setDate(d.getDate() - 1)
    setDatum(d)
  }

  const handleDateNext = () => {
    const d = new Date(datum)
    d.setDate(d.getDate() + 1)
    if (compareDates(d, new Date()) <= 0) {
      setDatum(d)
    }
  }

  useEffect(() => {
    dispatch(actions.merchant.fetchActivitiesByDate(datum))
  }, [dispatch, datum, selectedLanguage])

  return (
    <ActivitiesWrapper>
      <ActivitiesHeader>
        <div className='date-selection calendar-big'>
          <DatePicker
            locale={selectedLanguage}
            maxDate={new Date()}
            selected={datum}
            onChange={setDatum}
            calendarStartDay={1}
            customInput={<span className='selected-date'>{getDateText()}</span>}
          />

          <Link to='#' className='icon-button' onClick={handleDateBack}>
            <BackIcon />
          </Link>
          <Link to='#' className='icon-button' onClick={handleDateNext}>
            <NextIcon />
          </Link>
        </div>
        <div className='subtitle'>{tr('FRONTEND.DASHBOARD.ACTIVITIES_RECENT', 'Recent activities on this day')}</div>
      </ActivitiesHeader>

      <ActivitiesContent>
        {lastActivitiesFetching ? (
          <div className='loader-spinner'>
            <TailSpin color='#828282' height={55} width={55} />
          </div>
        ) : lastActivitiesError ? (
          <ErrorDisplay error={lastActivitiesError} />
        ) : isValidArray(lastActivities) ? (
          lastActivities
            .filter((_, index) => index < 6)
            .map((activity, index) => {
              const data = transformMerchantActivity(activity)

              return (
                <ActivityWrapper
                  key={index}
                  data-test='activity'
                  className={cn({ clickable: data.redirect?.length > 0 })}
                  onClick={() => handleActivityClick(data, index)}>
                  <div className='left'>
                    <div className='icon'>
                      {(invoiceFetching || invoicePreviewFetching) && index === previewIndex ? (
                        <TailSpin color='#828282' height={30} width={30} />
                      ) : (
                        <img src={getActivityIcon(data.type)} alt='' />
                      )}
                    </div>
                    <div className='description'>
                      {data.description}
                      {data.subtitle?.length ? <div>{data.subtitle}</div> : null}
                    </div>
                  </div>
                  <div className='amount'>{data.amount && formatMoney(data.amount.amount, data.amount.currency)}</div>
                </ActivityWrapper>
              )
            })
        ) : (
          <ActivityWrapper>
            <div className='icon'>
              <img src={activityNoneIcon} alt='' />
            </div>
            <div className='description'>{tr('FRONTEND.DASHBOARD.ACTIVITIES_NONE', 'No activities')}</div>
          </ActivityWrapper>
        )}
      </ActivitiesContent>
    </ActivitiesWrapper>
  )
}
