import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import cn from 'classnames'

import { MenuWrapper } from './LanguageMenu.styled'

import settings from '../../../settings'
import { actions } from 'mmfintech-backend-api'
import { initializeLanguage, languages, useDropDownMenu } from 'mmfintech-commons'

import { LoginStatusEnum } from 'mmfintech-commons-types'

import ArrowDownIcon from '../../../images/icons/input-arrow-down.svg?react'
import ArrowUpIcon from '../../../images/icons/input-arrow-up.svg?react'
import LanguageIcon from '../../../images/icons/input-select-country.svg?react'

export const LanguageMenu = ({ hamburger, theme = 'dark' }) => {
  const { userStatus, selectedLanguage } = useSelector(
    ({ user: { userStatus, selectedLanguage } }: any) => ({ userStatus, selectedLanguage }),
    shallowEqual
  )

  const [filteredLanguages, setFilteredLanguages] = useState([])

  const dispatch = useDispatch()

  const { visible, hideDropDown, toggleDropDown } = useDropDownMenu()

  const doSelectLanguage = (languageCode: string): void => {
    if (languageCode !== selectedLanguage) {
      if (userStatus === LoginStatusEnum.LOGGED_IN) {
        dispatch(actions.merchant.setLanguage(languageCode))
      } else {
        dispatch(actions.merchant.changeLanguage(languageCode))
      }
    }
    hideDropDown()
    initializeLanguage(languageCode)
  }

  const getLanguageName = () => {
    const data = filteredLanguages.find(item => item.code === selectedLanguage)
    return data ? data.name : ''
  }

  useEffect(() => {
    const filtered = [...(Array.isArray(settings.languages) ? settings.languages : [])]
      .map(code => languages.find(v => v.code === code))
      .filter(v => v != null)
    setFilteredLanguages(filtered)
    // eslint-disable-next-line
  }, [])

  if (!selectedLanguage) {
    return null
  }

  return (
    <MenuWrapper className={cn('theme-' + theme, { hamburger })} data-test='language-menu-wrapper'>
      <div className={cn('title-speedy', { opened: visible })} onClick={toggleDropDown}>
        <span className='icon'>
          <LanguageIcon />
        </span>
        <span className='selected'>{getLanguageName()}</span>
        <span className='arrow'>{visible ? <ArrowUpIcon /> : <ArrowDownIcon />}</span>
      </div>

      <div className={cn('content-speedy', { opened: visible })} data-test='language-menu-content'>
        <ul data-test='language-menu-list'>
          {filteredLanguages?.map(v => (
            <li key={v.code} onClick={() => doSelectLanguage(v.code)} data-test='language-menu-item'>
              {v.name}
            </li>
          ))}
        </ul>
      </div>
    </MenuWrapper>
  )
}
