import { GraphPane } from './GraphPane'
import { AccountPane } from './AccountPane'
import { CashFlowPane } from './CashFlowPane'
import { InvoicesPane } from './InvoicesPane'
import { DashboardContainer } from '../Dashboard.styled'

import { isMerchantIndividual } from 'mmfintech-backend-api'
import { shallowEqual, useSelector } from 'react-redux'

export const OwnerDashboard = () => {
  const { merchant } = useSelector(
    ({ user: { merchant } }: any) => ({
      merchant
    }),
    shallowEqual
  )

  return (
    <DashboardContainer>
      <AccountPane />
      <GraphPane />
      {isMerchantIndividual(merchant) ? <CashFlowPane /> : <InvoicesPane />}
    </DashboardContainer>
  )
}
