import { useContext } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { Button } from 'mmfintech-portal-commons'
import { ManageCookies } from './ManageCookies'
import { ConsentContent, ConsentFooter, ConsentHeader, CookieConsentWrapper } from './CookieConsent.styled'

import { configuration } from 'mmfintech-backend-api'
import { tr, GlobalContext } from 'mmfintech-commons'

import CookiesIcon from '../../images/icons/cookies.svg'

export const CookieConsent = ({ setCookie }) => {
  const { modalShow } = useContext(GlobalContext)

  useSelector(({ user }: any) => user.selectedLanguage, shallowEqual)

  const onAcceptAllClick = () => {
    setCookie(
      'cookie.consent',
      JSON.stringify({
        necessaryCookies: true,
        optionalCookies: true
      }),
      {
        days: 365,
        maxAge: 31536000,
        domain: configuration.isLocal() ? null : '.atabank.io'
      }
    )
  }

  const onManageCookiesClick = () => {
    modalShow({
      options: { maxWidth: 940, size: 'large' },
      content: <ManageCookies setCookie={setCookie} />
    })
  }

  return (
    <CookieConsentWrapper>
      <ConsentHeader>
        <img src={CookiesIcon} alt='' />
        {tr('FRONTEND.COOKIE_CONSENT.COOKIE_POLICY', 'Cookies Policy')}
      </ConsentHeader>

      <ConsentContent>
        {tr(
          'FRONTEND.COOKIE_CONSENT.PERSONALISED_XP',
          'We use cookies to personalize your experience on ATA Bank portal. They are all about to personalize content and ads, provide social media features and to analyze our traffic.'
        )}
      </ConsentContent>

      <ConsentFooter>
        <Button
          className='manage'
          color='round-primary'
          text={tr('FRONTEND.COOKIE_CONSENT.MANAGE_COOKIES', 'Manage Cookies')}
          onClick={onManageCookiesClick}
          data-test='button-manage-cookies'
        />
        <Button
          className='consent'
          color='round-secondary'
          text={tr('FRONTEND.COOKIE_CONSENT.ACCEPT_ALL', 'Accept All')}
          onClick={onAcceptAllClick}
          data-test='button-accept-all'
        />
      </ConsentFooter>
    </CookieConsentWrapper>
  )
}
