import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { ModalContainer } from '../Security.styled'
import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import { CustomInput, EditContainer } from '../../../../components'

import { actions } from 'mmfintech-backend-api'
import {
  checkFinalValue,
  fixPhoneNumber,
  isEmptyString,
  OtpContext,
  tr,
  translateError,
  useFormValues
} from 'mmfintech-commons'

export const ChangeMobileModal = ({
  originalNumber = undefined,
  profile,
  onChangeMessage = undefined,
  changePhoneError = undefined
}) => {
  const { setOtpOnSuccess, setOtpOnError } = useContext(OtpContext)
  const { twoFactorPhone } = profile

  const formValues = useFormValues({
    phone: { required: true, validation: 'phone' }
  })

  const [phoneError, setPhoneError] = useState(null)

  const dispatch = useDispatch()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setOtpOnSuccess(() => ({ data }) => {
      if (!data?.challenge) {
        dispatch(actions.merchant.profile())
        onChangeMessage && onChangeMessage('FRONTEND.SECURITY.PHONE.SUCCESS_MESSAGE')
      }
    })
    setOtpOnError(() => ({ error }) => {
      onChangeMessage && onChangeMessage('FRONTEND.SECURITY.PHONE.FAIL_MESSAGE', error)
    })

    const result = checkFinalValue(fixPhoneNumber(formValues.getValue('phone')), {
      validation: 'phone',
      required: true
    })

    if (!result.valid) {
      setPhoneError(translateError(result))
      return
    }
    if (fixPhoneNumber(originalNumber || '') === fixPhoneNumber(formValues.getValue('phone'))) {
      setPhoneError(
        tr('FRONTEND.SECURITY.PHONE.ERROR_EQUAL', 'The phone number matches the one already entered in the system.')
      )
      return
    }

    dispatch(actions.security.cleanup())
    setPhoneError(null)
    dispatch(actions.security.changePhoneRequest(formValues.prepare()))
  }

  useEffect(() => {
    formValues.handleErrors(changePhoneError)
    //eslint-disable-next-line
  }, [changePhoneError])

  return (
    <ModalContainer noValidate onSubmit={handleSubmit} data-test='change-mobile-number-modal'>
      <div className='title'>
        {isEmptyString(twoFactorPhone)
          ? tr('FRONTEND.SECURITY.PHONE.SUBTITLE_SET', 'Set Mobile Number')
          : tr('FRONTEND.SECURITY.PHONE.SUBTITLE', 'Change Mobile Number')}
      </div>

      <EditContainer className='container'>
        <CustomInput
          type='phone'
          name='phone'
          label={tr('FRONTEND.SECURITY.PHONE.NUMBER', 'Mobile Number')}
          error={formValues.getError('phone') || phoneError}
          {...formValues.registerInput('phone')}
          autoComplete='off'
          hideErrorLine
          autoFocus
          required
        />

        <ErrorDisplay error={[changePhoneError, phoneError]} />

        <div className='buttons'>
          <Button
            type='submit'
            color='primary'
            text={
              isEmptyString(twoFactorPhone)
                ? tr('FRONTEND.SECURITY.BUTTON_SET', 'Set')
                : tr('FRONTEND.SECURITY.BUTTON_CHANGE', 'Change')
            }
            data-test='button-change'
            iconPosition='right'
            icon='right-arrow'
          />
        </div>
      </EditContainer>
    </ModalContainer>
  )
}
