// noinspection CssUnusedSymbol

import styled from 'styled-components'

export const BalanceStatementWrapper = styled.div`
  display: grid;
  place-items: center;
  width: 100vw;
  max-width: 58rem;

  background: #1a1a1a;
  padding: 4.5rem 2.5rem;

  @media (min-width: 400px) {
    padding: 4rem 5rem;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  form {
    margin: 0;
    padding: 0;
    width: 100%;

    .title {
      display: block;
      margin-bottom: 4rem;

      color: #ffffff;
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 450;
      line-height: 100%;
    }

    div.message-wrapper {
      margin-bottom: 5rem;

      & > span.message {
        font-size: 1.4rem;
        color: ${({ theme }) => theme.contentWrapper.staticColor};
      }
    }

    .year-month {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1rem;
      position: relative;

      @media (min-width: 600px) {
        grid-template-columns: 1fr 2fr;
        grid-gap: 4rem;
      }
    }

    .period-wrap {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin-bottom: 6rem;
    }

    .button {
      width: 30rem;
    }
  }
`
