import { shallowEqual, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { tr, isValidString } from 'mmfintech-commons'
import { paths, useOnboardingBannerQry } from 'mmfintech-backend-api'

import { Button } from 'mmfintech-portal-commons'
import { Centered, OnboardingBannerContent, OnboardingBannerWrapper } from './OnboardingBanner.styled'

import { MerchantAccountTypeEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

import ArrowRightIcon from '../../../images/icons/arrow-right-white-long.svg?react'
import WarningTriangleIcon from '../../../images/icons/warning-triangle.svg?react'

export const OnboardingBanner = ({ arrow = undefined, button, light = undefined, ...rest }) => {
  const { merchant } = useSelector(
    ({ user: { merchant, selectedLanguage } }: any) => ({
      merchant,
      selectedLanguage
    }),
    shallowEqual
  )
  const { accountType, onboardingStatus } = merchant || {}

  const history = useHistory()

  const { startOrContinueOnboarding } = useOnboardingBannerQry({
    startOnboarding: () => history.push(paths.onboarding.selectType())
  })

  const Banner = ({ header = undefined, content, buttonCaption = undefined, handleClick = undefined }) => {
    const clickable = typeof handleClick == 'function'

    return (
      <OnboardingBannerWrapper button={button} onClick={() => !button && clickable && handleClick()} {...rest}>
        <Centered className='left'>
          <WarningTriangleIcon />
        </Centered>

        <OnboardingBannerContent>
          {isValidString(header) && <div className='header'>{header}</div>}
          {content}
        </OnboardingBannerContent>

        {clickable && (
          <Centered className='right'>
            {button ? (
              <Button
                type='button'
                color='alternative'
                onClick={handleClick}
                data-test='verify-button'
                text={buttonCaption}
              />
            ) : (
              <ArrowRightIcon />
            )}
          </Centered>
        )}
      </OnboardingBannerWrapper>
    )
  }

  if (accountType !== MerchantAccountTypeEnum.PROSPECT) {
    return null
  }

  if (
    onboardingStatus === OnboardingStatusEnum.NOT_STARTED ||
    onboardingStatus === OnboardingStatusEnum.IN_PROGRESS ||
    onboardingStatus === OnboardingStatusEnum.REJECTED_RETRY
  ) {
    return (
      <Banner
        header={tr('FRONTEND.ONBOARDING.RESUME_ALERT_TITLE', 'Complete your account')}
        content={tr(
          'FRONTEND.ONBOARDING.RESUME_ALERT_TEXT',
          'Verify to get access to all features and increased payment limits.'
        )}
        buttonCaption={
          onboardingStatus === OnboardingStatusEnum.NOT_STARTED
            ? tr('FRONTEND.ONBOARDING.RESUME_ALERT_GET_STARTED', 'Get Started')
            : tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')
        }
        handleClick={() => startOrContinueOnboarding()}
      />
    )
  }

  if (
    onboardingStatus === OnboardingStatusEnum.UNDER_REVIEW ||
    onboardingStatus === OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL ||
    onboardingStatus === OnboardingStatusEnum.WAITING_OPS_APPROVAL
  ) {
    return (
      <Banner
        content={tr('FRONTEND.ONBOARDING.RESUME_ALERT_UNDER_REVIEW', 'Your account verification is under review.')}
      />
    )
  }

  if (onboardingStatus === OnboardingStatusEnum.REJECTED_FINAL) {
    return (
      <Banner
        content={tr(
          'FRONTEND.ONBOARDING.RESUME_ALERT_REJECTED',
          'Please contact Customer Service to get support on your account verification.'
        )}
      />
    )
  }

  return null
}
