// noinspection DuplicatedCode

import { useHistory } from 'react-router-dom'

import SpendingLimitBarUser from '../../requests/SpendingLimitBarUser'

import { Button } from 'mmfintech-portal-commons'
import { TransactionsTable } from '../../transactions'
import { DashboardContainer } from '../Dashboard.styled'
import { AccountOverviewList } from './AccountOverviewList'
import { StaffDashboardWrapper } from './StaffDashboard.styled'

import { tr } from 'mmfintech-commons'
import { useTransactionsFilter, actions, paths, usePermissionsRedux } from 'mmfintech-backend-api'

import DepositIcon from '../../../images/icons/deposit.svg?react'
import TransferIcon from '../../../images/icons/transfer.svg?react'

export const StaffDashboard = () => {
  const { hasWritePermission } = usePermissionsRedux()
  useTransactionsFilter({})
  const history = useHistory()

  const handleDepositClick = () => {
    actions.routing.setDepositOrigin('')
    history.push(paths.banking.deposit())
  }

  const handleTransferClick = () => {
    actions.routing.setWithdrawOrigin('')
    history.push(paths.banking.send())
  }

  return (
    <DashboardContainer>
      <StaffDashboardWrapper>
        {hasWritePermission() && (
          <div className='dashboard-nav'>
            <SpendingLimitBarUser />

            <div>
              <Button
                color='round-primary'
                className='sticky mr-1'
                data-test='button-deposit'
                icon={<DepositIcon className='fill' />}
                text={tr('FRONTEND.DASHBOARD.BUTTON_DEPOSIT', 'Deposit')}
                onClick={handleDepositClick}
              />
              <Button
                color='round-primary'
                className='sticky'
                data-test='button-transfer'
                icon={<TransferIcon className='fill' />}
                text={tr('FRONTEND.DASHBOARD.BUTTON_SEND', 'Send Money')}
                onClick={handleTransferClick}
              />
            </div>
          </div>
        )}
        <AccountOverviewList />
        <TransactionsTable origin='transactions' />
      </StaffDashboardWrapper>
    </DashboardContainer>
  )
}
