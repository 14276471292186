import PropTypes from 'prop-types'

import ReactTooltip from 'react-tooltip'
import GlobalWrapperStyled from './GlobalWrapper.styled'

export const GlobalWrapper = ({ type, children }) => {
  return (
    <GlobalWrapperStyled className={`speedy-background-${type}`}>
      <div className='container'>{children}</div>
      <ReactTooltip id='info-tooltip' type='dark' effect='solid' place='top' multiline={true} isCapture={true} />
    </GlobalWrapperStyled>
  )
}

GlobalWrapper.propTypes = {
  type: PropTypes.oneOf([1, 2, 3, 4])
}
