// noinspection CssUnusedSymbol

import styled from 'styled-components'

export const StaffDashboardWrapper = styled.div`
  .dashboard-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    button {
      width: fit-content;
    }

    @media (min-width: 900px) {
      flex-flow: row nowrap;
      margin-bottom: 0;
    }
  }
`
