import cn from 'classnames'

import {
  CheckBoxGroupFooter,
  CheckBoxGroupHeader,
  CheckBoxGroupItem,
  CheckBoxGroupWrapper
} from './CheckBoxGroup.styled'

import { tr } from 'mmfintech-commons'

import { CheckBoxGroupProps } from './CheckBoxGroup.types'

export const CheckBoxGroup = ({ footer, header, items, checked, setChecked }: CheckBoxGroupProps) => {
  return (
    <CheckBoxGroupWrapper onClick={e => e.stopPropagation()}>
      {header && <CheckBoxGroupHeader>{header}</CheckBoxGroupHeader>}

      {Array.isArray(items) &&
        items.map((item, index) => {
          const { name, label, localizationKey } = item
          return (
            <CheckBoxGroupItem key={index}>
              <span>{localizationKey ? tr(localizationKey, label) : label}</span>
              <span
                className={cn('checkbox', { checked: checked(name) })}
                onClick={() => setChecked(name)}
                data-test={`toggle-${name}`}
              />
            </CheckBoxGroupItem>
          )
        })}

      {footer && <CheckBoxGroupFooter>{footer}</CheckBoxGroupFooter>}
    </CheckBoxGroupWrapper>
  )
}
