import { useContext, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { ActivitiesPane } from './ActivitiesPane'
import { InvoicePreview } from '../invoices'
import { OwnerDashboard } from './OwnerDashboard'
import { StaffDashboard } from './StaffDashboard'
import { NewInvoiceButton } from '../invoices'
import { ChangeMobileModal } from '../settings'
import { DashboardContainer, DashboardWrapper } from './Dashboard.styled'

import { actions } from 'mmfintech-backend-api'
import { GlobalContext } from 'mmfintech-commons'

import { CustomerRoleEnum, TwoFactorTypeEnum } from 'mmfintech-commons-types'

export const Dashboard = () => {
  const { modalShow } = useContext(GlobalContext)

  const { profile, invoicePreview, update2faPostponeStatus, customerRole } = useSelector(
    ({
      invoices: { invoicePreview },
      user: { profile, selectedLanguage, update2faPostponeStatus, customerRole }
    }: any) => ({
      profile,
      invoicePreview,
      selectedLanguage,
      update2faPostponeStatus,
      customerRole
    }),
    shallowEqual
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.merchant.profile())
    dispatch(actions.merchant.fetchDashboard())
  }, [dispatch])

  useEffect(() => {
    switch (update2faPostponeStatus) {
      case 'POSTPONE':
        dispatch(actions.security.postponeTwoFactorUpdate('PHONE'))
        modalShow({
          options: { maxWidth: 590, size: 'medium' },
          content: <ChangeMobileModal profile={profile} />
        })
        break

      case 'VERIFIED':
        dispatch(
          actions.security.changeTwoFactorAuthMethod(TwoFactorTypeEnum.SMS, () => {
            dispatch(actions.merchant.profile())
          })
        )
        break

      default:
      // nothing
    }
    // eslint-disable-next-line
  }, [update2faPostponeStatus])

  useEffect(() => {
    if (invoicePreview) {
      modalShow({
        options: {
          maxWidth: 820,
          size: 'large',
          closeIconPosition: 'speedy-special',
          transparent: true,
          onClose: () => {
            dispatch(actions.invoice.resetPreview())
          }
        },
        content: <InvoicePreview invoice={invoicePreview} />
      })
    }
    // eslint-disable-next-line
  }, [dispatch, invoicePreview])

  return (
    <DashboardWrapper>
      {customerRole === CustomerRoleEnum.STAFF ? (
        <DashboardContainer>
          <StaffDashboard />
        </DashboardContainer>
      ) : (
        <OwnerDashboard />
      )}

      <ActivitiesPane />

      <NewInvoiceButton />
    </DashboardWrapper>
  )
}
