import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 9rem;
  right: 3rem;

  border-radius: 50%;
  cursor: pointer;
  filter: drop-shadow(0px 100px 109px rgba(255, 255, 255, 0.08))
    drop-shadow(0px 41.7776px 45.5376px rgba(255, 255, 255, 0.0575083))
    drop-shadow(0px 22.3363px 24.3466px rgba(255, 255, 255, 0.0476886))
    drop-shadow(0px 12.5216px 13.6485px rgba(255, 255, 255, 0.04))
    drop-shadow(0px 6.6501px 7.24861px rgba(255, 255, 255, 0.0323114))
    drop-shadow(0px 2.76726px 3.01631px rgba(255, 255, 255, 0.0224916));

  @media (min-width: 900px) {
    position: fixed;
    bottom: 3rem;
    left: 3rem;
    top: unset;
    right: unset;
  }

  @media (min-width: 1690px) {
    margin-top: 0;
    margin-bottom: 0;
    position: fixed;
    left: 50%;
    margin-left: -845px;
    bottom: 7rem;
  }
`
