// noinspection CssUnusedSymbol

import styled from 'styled-components'

export const AccountPaneWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin-top: 3rem;
  margin-bottom: 5rem;

  @media (min-width: 900px) {
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7.5rem;
  }
`

export const BalanceContainer = styled.div`
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin-bottom: 2rem;

  color: #ffffff;
  font-family: inherit;
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  .label {
    color: #808080;
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
  }

  @media (min-width: 900px) {
    margin-bottom: 0;
  }
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;

  .button {
    height: 5rem;
    width: 30rem;
    max-width: 100%;
    font-weight: 500;

    margin-left: 0;
    margin-bottom: 2rem;
    :first-child {
      margin-left: 0;
    }
  }

  @media (min-width: 640px) {
    width: auto;
    flex-flow: row nowrap;

    .button {
      height: 3.5rem;
      width: auto;
      margin-left: 1.5rem;
      margin-bottom: 0;
      :first-child {
        margin-left: 0;
      }
    }
  }
`
