import styled from 'styled-components'

export const NewInvoiceWrapper = styled.div`
  position: relative;
  height: auto;
  width: 98vw;
  max-width: 94rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  background: #1a1a1a;
  padding: 7rem 3rem 3rem;

  @media (min-width: 940px) {
    display: grid;
    grid-template-columns: 27rem 1fr;
    grid-gap: 3rem;

    height: 63rem;
    padding: 7rem 6.3rem 6.7rem 9rem;
  }
`

export const NewInvoiceLeft = styled.div`
  width: auto;
  margin-right: 0;
  margin-bottom: 9rem;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;

  .header {
    .title {
      color: #ffffff;
      font-family: inherit;
      font-size: 6.4rem;
      font-style: normal;
      font-weight: normal;
      line-height: 100%;
      margin-bottom: 3rem;
      text-align: center;
    }
    .subtitle {
      color: #afafaf;
      font-family: inherit;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: normal;
      line-height: 140%;
      text-align: center;
      margin-bottom: 3rem;
    }
  }

  @media (min-width: 940px) {
    height: 100%;
    width: 27rem;
    margin-right: 3rem;
    margin-bottom: 0;
    align-items: flex-start;

    .header {
      .title {
        text-align: left;
      }
      .subtitle {
        text-align: left;
        margin-bottom: 0;
      }
    }
  }

  .buttons {
    width: 100%;
    max-width: 27rem;
    display: flex;
    flex-flow: column nowrap;

    .button {
      z-index: 21;
      height: 7.3rem;
      margin-top: 2rem;

      font-size: 2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;

      justify-content: flex-start;

      .icon-left {
        margin-left: 1rem;
      }
    }
  }
`

export const NewInvoiceRight = styled.div`
  height: 45rem;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    position: absolute;
    right: 50%;
    top: 50%;
    margin-right: -333px;
    margin-top: -333px;
  }

  @media (min-width: 940px) {
    height: 100%;
  }

  .drop-target {
    width: 100%;
    height: 100%;
    max-width: 25rem;
    max-height: 25rem;

    .drop-container {
      border: none;
      border-radius: 50%;
    }
    .drop-label {
      font-size: 2rem;
    }
  }

  .upload-box {
    cursor: pointer;
    height: 100%;
    border: 2px dashed #ffffff;
    border-radius: 50%;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    color: #ffffff;
    font-size: 2.4rem;
    font-family: inherit;
    font-style: normal;
    font-weight: normal;
    line-height: 90%;

    .file-types {
      font-size: 1.3rem;
      line-height: 120%;
      margin-top: 0.6rem;
    }
  }
`
