import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { Button, DragAndDrop, ErrorDisplay, FileInput } from 'mmfintech-portal-commons'
import { NewInvoiceLeft, NewInvoiceRight, NewInvoiceWrapper } from './NewInvoiceModal.styled'

import settings from '../../../settings'
import { actions, paths } from 'mmfintech-backend-api'
import { checkFileUpload, tr, utils } from 'mmfintech-commons'

import UploadInvoiceImage from '../../../images/speedy-upload-invoice.png'
import Icon from '../../../images/icons/deposit.svg?react'

interface NewInvoiceModalProps {
  onClose: () => void
}

export const NewInvoiceModal = ({ onClose }: NewInvoiceModalProps) => {
  const { uploadInvoiceError, uploadInvoiceFetching } = useSelector(
    ({
      user: { merchant },
      enotas: { metadataError },
      invoices: { uploadInvoiceError, uploadInvoiceFetching }
    }: any) => ({
      merchant,
      metadataError,
      uploadInvoiceError,
      uploadInvoiceFetching
    }),
    shallowEqual
  )

  const [fileError, setFileError] = useState(null)

  const history = useHistory()
  const dispatch = useDispatch()

  const handleClose = () => {
    'function' === typeof onClose && onClose()
  }

  const handleSpeedyPayClick = () => {
    handleClose()
    dispatch(actions.invoice.setInvoiceNewOrigin('dashboard'))
    history.push(paths.invoices.create())
  }

  const handleFileError = (errors: any) => setFileError(checkFileUpload(errors))

  const handleFileSelected = (files: FileList | File[]) => {
    setFileError(null)
    if (utils.is.validFileArrOrFileList(files)) {
      dispatch(
        actions.invoice.uploadInvoice(files[0], () => {
          handleClose()
          history.push(paths.invoices.upload())
        })
      )
    }
  }

  return (
    <NewInvoiceWrapper data-test='invoice-new-modal'>
      <NewInvoiceLeft className='left-side'>
        <div className='header'>
          <div className='title'>{tr('FRONTEND.INVOICE.NEW.TITLE', 'New Invoice')}</div>
          <div className='subtitle'>
            {tr('FRONTEND.INVOICE.NEW.SUBTITLE', 'Create a new invoice or just drop here your invoice file.')}
          </div>
          <ErrorDisplay error={[fileError, uploadInvoiceError]} />
        </div>
        <div className='buttons'>
          <Button
            color='round-primary'
            icon={<Icon className='mr-1' width={26} height={26} />}
            text='ATA Bank Pay'
            disabled={uploadInvoiceFetching}
            onClick={handleSpeedyPayClick}
            data-test='button-speedy-pay'
          />
        </div>
      </NewInvoiceLeft>

      <NewInvoiceRight className='right-side'>
        <img src={UploadInvoiceImage} alt='' />
        <DragAndDrop
          onDrop={handleFileSelected}
          onError={handleFileError}
          maxFileSize={settings.defaultUploadInvoiceMaxFileSize}
          acceptType={settings.defaultUploadInvoiceAcceptType}>
          <FileInput
            maxNumber={1}
            maxFileSize={settings.defaultUploadInvoiceMaxFileSize}
            acceptType={settings.defaultUploadInvoiceAcceptType}
            onChange={handleFileSelected}
            onError={handleFileError}>
            {({ onFileUpload, onRemoveAll }) => (
              <>
                <div
                  className='upload-box'
                  data-test='upload-box'
                  onClick={() => {
                    onRemoveAll()
                    onFileUpload()
                  }}>
                  {tr('FRONTEND.INVOICE.NEW.DROP_HERE', 'drop invoice here')}
                  <div className='file-types'>{settings.defaultUploadInvoiceAcceptType.join(', ')}</div>
                </div>
              </>
            )}
          </FileInput>
        </DragAndDrop>
      </NewInvoiceRight>
    </NewInvoiceWrapper>
  )
}
