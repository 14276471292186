import { Link } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'

import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import { CustomInput, GlobalTitle, GlobalWrapper } from '../../../components'

import { tr } from 'mmfintech-commons'
import { paths, useLogin } from 'mmfintech-backend-api'
import { LoginContent, OuterLink } from './Login.styled'

export const Login = () => {
  const { loginError, loginFetching, otpFetching, otpRequestError } = useSelector(
    ({ otp: { otpFetching, otpRequestError }, user: { loginError, loginFetching, selectedLanguage } }: any) => ({
      loginError,
      loginFetching,
      otpFetching,
      otpRequestError,
      selectedLanguage
    }),
    shallowEqual
  )

  const { formValues, handleSubmit } = useLogin()

  return (
    <GlobalWrapper type={1}>
      <GlobalTitle>{tr('FRONTEND.LOGIN.TITLE', 'Login')}</GlobalTitle>
      <LoginContent noValidate onSubmit={handleSubmit}>
        <CustomInput
          icon='email'
          type='email'
          data-test='email'
          label={tr('FRONTEND.LOGIN.EMAIL', 'Email')}
          {...formValues.registerInput('email')}
          disabled={loginFetching}
          hideErrorLine
          autoFocus
        />

        <CustomInput
          icon='lock'
          type='password'
          data-test='password'
          label={tr('FRONTEND.LOGIN.PASSWORD', 'Password')}
          {...formValues.registerInput('password')}
          disabled={loginFetching}
          autoComplete='off'
          hideErrorLine
        />

        <ErrorDisplay error={[loginError, otpRequestError]} />

        <div className='buttons'>
          <Button
            type='submit'
            color='primary'
            data-test='button-submit'
            text={tr('FRONTEND.LOGIN.BUTTON_LOGIN', 'Log in')}
            disabled={!formValues.getValue('email')?.length || !formValues.getValue('password')?.length}
            loading={otpFetching || loginFetching}
            iconPosition='right'
            icon='right-arrow'
          />
        </div>

        <div className='inner-link'>
          {tr('FRONTEND.LOGIN.NO_ACCOUNT', "Don't have an account?")}{' '}
          <Link to={paths.signUp()} data-test='sign-up'>
            <span>{tr('FRONTEND.LOGIN.SIGN_UP_FREE', 'Sign up for FREE')}</span>
          </Link>
        </div>
      </LoginContent>

      <OuterLink>
        <Link to={paths.forgotPassword()} data-test='forgot-password'>
          {tr('FRONTEND.LOGIN.FORGOT_PASSWORD', 'Forgot password?')}
        </Link>
      </OuterLink>
    </GlobalWrapper>
  )
}
