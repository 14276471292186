import { Chart } from './Chart'
import { FxRateButton } from './FxRateButton'
import { ExchangePaneContent, ExchangePaneWrapper, GraphPaneWrapper } from './GraphPane.styled'

import { useExchangePairs } from 'mmfintech-backend-api'

export const GraphPane = () => {
  const { updatedFxRates } = useExchangePairs({
    fixedRates: []
  })

  return (
    <GraphPaneWrapper>
      <Chart />
      <ExchangePaneWrapper>
        <ExchangePaneContent>
          {updatedFxRates
            ?.filter((_node, index) => index < 6)
            .map((node, index) => <FxRateButton key={index} node={node} />)}
        </ExchangePaneContent>
      </ExchangePaneWrapper>
    </GraphPaneWrapper>
  )
}
