// noinspection DuplicatedCode

import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'

import { Button } from 'mmfintech-portal-commons'
import { AccountPaneWrapper, BalanceContainer, ButtonsContainer } from './AccountPane.styled'

import { actions, paths } from 'mmfintech-backend-api'
import { tr, isValidArray, isValidString, formatMoney } from 'mmfintech-commons'

import DepositIcon from '../../../../images/icons/deposit.svg?react'
import TransferIcon from '../../../../images/icons/transfer.svg?react'
import ExchangeIcon from '../../../../images/icons/exchange.svg?react'

export const AccountPane = () => {
  const { aggregatedBalance, customerRole } = useSelector(
    ({ user: { aggregatedBalance, customerRole, selectedLanguage } }: any) => ({
      aggregatedBalance,
      customerRole,
      selectedLanguage
    }),
    shallowEqual
  )

  const [balance, setBalance] = useState(null)

  const history = useHistory()

  const handleDepositClick = () => {
    actions.routing.setDepositOrigin('')
    history.push(paths.banking.deposit())
  }

  const handleTransferClick = () => {
    actions.routing.setWithdrawOrigin('')
    history.push(paths.banking.send())
  }

  const handleExchangeClick = () => {
    actions.routing.setExchangeOrigin('')
    history.push(paths.banking.exchange())
  }

  useEffect(() => {
    if (isValidArray(aggregatedBalance)) {
      setBalance(aggregatedBalance[aggregatedBalance.length - 1])
    } else {
      setBalance(null)
    }
  }, [aggregatedBalance])

  return (
    <AccountPaneWrapper>
      {isValidString(customerRole) && (
        <>
          <BalanceContainer>
            {balance && (
              <>
                <div className='label'>{tr('FRONTEND.DASHBOARD.TOTAL_ACCOUNT_VALUE', 'Total Account Value')}</div>
                <div data-test='balance'>{formatMoney(balance?.amount, balance?.currencyCode)}</div>
              </>
            )}
          </BalanceContainer>

          <ButtonsContainer>
            <Button
              color='round-primary'
              className='sticky'
              data-test='button-deposit'
              icon={<DepositIcon className='fill' />}
              text={tr('FRONTEND.DASHBOARD.BUTTON_DEPOSIT', 'Deposit')}
              onClick={handleDepositClick}
            />
            <Button
              color='round-primary'
              className='sticky'
              data-test='button-transfer'
              icon={<TransferIcon className='fill' />}
              text={tr('FRONTEND.DASHBOARD.BUTTON_SEND', 'Send Money')}
              onClick={handleTransferClick}
            />
            <Button
              color='round-primary'
              className='sticky'
              data-test='button-transfer'
              icon={<ExchangeIcon className='fill' />}
              text={tr('FRONTEND.DASHBOARD.BUTTON_EXCHANGE', 'Exchange')}
              onClick={handleExchangeClick}
            />
          </ButtonsContainer>
        </>
      )}
    </AccountPaneWrapper>
  )
}
