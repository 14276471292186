// noinspection CssUnusedSymbol

import styled from 'styled-components'

export const CustomSearchWrapper = styled.div`
  display: inline-block;
  border: 1px solid #ffffff;
  border-radius: 1.9rem;
  height: 3.8rem;
  width: auto;

  color: #ffffff;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;

  .inner-container {
    width: 3.6rem;
    height: 100%;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    .search-icon {
      width: 3.6rem;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 50%;
      cursor: pointer;
    }

    input {
      width: 100%;
      height: 100%;
      outline: none;

      color: #ffffff;
      font-size: 1.6rem;
      border: none;
      background: transparent;

      ${'' /* placeholder styling */}
      &::-webkit-input-placeholder {
        color: #808080;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: normal;
      }
      &:-ms-input-placeholder {
        color: #808080;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: normal;
      }
      &::placeholder {
        color: #808080;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: normal;
      }
    }

    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  &.opened {
    .inner-container {
      width: 23rem;
      padding-left: 1.8rem;
    }
  }
`
