import styled from 'styled-components'

export const ManageCookiesWrapper = styled.div`
  background: #1a1a1a;
  padding: 6rem 5rem 7.5rem;

  width: 100vw;
  max-width: 94rem;

  display: flex;
  flex-direction: column;

  color: #ffffff;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;

  h2 {
    font-size: 3.6rem;
    font-style: normal;
    font-weight: normal;
    line-height: 90%;

    margin: 4.5rem 0 2.5rem;
    :first-of-type {
      margin-top: 0;
    }
  }
`

export const SwitchContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 7rem;
  grid-gap: 5rem;

  p {
    margin: 0 0 1rem;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 5rem;
  padding-left: 4rem;

  .button {
    height: 7rem;
    width: 26rem;

    font-size: 2rem;
    font-weight: normal;
    line-height: 140%;

    position: relative;
    svg {
      position: absolute;
      left: 3rem;
      margin: 0;
    }
  }
`
